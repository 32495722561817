import React, { useEffect } from "react";
import Layout from "../components/layoutAr";
import Landing from "../components/landing/landingArStickers";
import Seo from "../components/seoAr";

const IndexPage = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/")) {
      document.querySelector("body").classList.add("rightToLeft");
      document.querySelector("body").classList.add("arabic-fonts");
    }
  }, []);
  return (
    <Layout>
      <Seo title="الصفحة الأساسية | تأمين سنكرس ضدّ الجوع" lang="ar" />
      {/* Campaign is On */}
      <Landing />
      <df-messenger
        project-id="snickersinsurancechatbot2-ixwh"
        agent-id="55748fc3-8c30-4119-9c6c-f5c20ece0375"
        language-code="ar"
      >
        <df-messenger-chat-bubble chat-title="Peggy"></df-messenger-chat-bubble>
      </df-messenger>
      {/* Campaign Closed */}
      {/* <CampaignClosed /> */}
    </Layout>
  );
};

export default IndexPage;
